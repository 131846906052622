<template>
  <div>
    <CCol md="12" lg="12">
      <CCard class="shadow-sm">
        <CCardBody>
          <br />
          <CRow>
            <CCol md="10">
              <h2 class="font-weight-bold">{{ $t('participateItems') }}</h2>
            </CCol>
            <CCol md="2" class="text-right">
              <a @click="$router.go(-1)">
                <CButton>
                  <CIcon name="cil-arrow-thick-from-right" />
                  {{ $t('back') }}
                </CButton>
              </a>
            </CCol>
          </CRow>
          <hr v-if="isSuspend !== true" />
          <br />
          <CRow v-if="isSuspend !== true">
            <CCol sm="9" md="10" lg="10" col="9">
              <div class="row">
                <CIcon size="2xl" name="cil-basket" class="text-success col-1" />
                <select class="custom-select col-11" v-model="shopObjectId">
                  <option selected>{{ $t('selectshop') }} ...</option>
                  <option v-for="shop in shops" :key="shop.objectId" :value="shop.objectId">
                    {{ shop.shopName }} - {{ shop.branchName }}
                  </option>
                </select>
              </div>
            </CCol>
            <CCol sm="3" md="2" col="3">
              <CButton block v-on:click="getProduct()" color="success">
                {{ $t('submit') }}
              </CButton>
            </CCol>
          </CRow>
          <hr />
          <br />
          <CRow>
            <CCol md="7">
              <h4 class="font-weight-bold">
                <em class="fas fa-shopping-cart"></em>
                {{ $t('selectdProduct') }} {{ productSelectedTotal }} {{ $t('item') }}
              </h4>
            </CCol>
            <CCol md="5">
              <CButton v-if="isSuspend !== true" color="dark" block @click="productModal = true">
                {{ $t('participateItems') }}
              </CButton>
            </CCol>
          </CRow>
          <br />

          <CDataTable :items="items" :fields="fields" hover border>
            <template #delete="{ item }">
              <td class="py-2 text-center">
                <CButton color="danger" @click="removeItem(`${item.objectId}`)" variant="outline" square size="sm">
                  <em class="fa fa-trash"></em>
                </CButton>
              </td>
            </template>
          </CDataTable>
          <CButton block v-if="isSuspend !== true" @click="popupModal = true" color="success">
            {{ $t('save') }}
          </CButton>

          <CRow>
            <CModal color="white" :show.sync="productModal" size="lg" centered :footer="footer">
              <h3 class="font-weight-bold">{{ $t('selectProductForPointAccumulation') }}</h3>

              <table style="width:100%" aria-describedby="">
                <th scope="col"></th>
                <tr>
                  <td style="width:80%">
                    <input v-model.trim="keyword" class="cursor form-control" :placeholder="$t('search')"
                      @input="getProduct()" />
                  </td>
                  <td style="width:13%">
                    <CButton v-on:click="getProduct()" block color="success">
                      {{ $t('search') }}
                    </CButton>
                  </td>
                </tr>
              </table>

              <br />
              <div style="overflow-x:auto;">
                <table style="width:100%;" aria-describedby="">
                  <th scope="col"></th>
                  <tr>
                    <td>
                      <CButton style="white-space: nowrap;" v-if="categoryObjectId === ''" color="dark"
                        @click="getProductByCategory('')" block>
                        {{ $t('allProduct') }}
                      </CButton>
                      <CButton style="white-space: nowrap;" v-else color="dark" variant="outline"
                        @click="getProductByCategory('')" block>
                        {{ $t('allProduct') }}
                      </CButton>
                    </td>
                    <td v-for="(item, index) in category" :key="index" style="width:14%">
                      <CButton v-if="categoryObjectId === item.objectId" @click="getProductByCategory(item.objectId)"
                        color="dark" style="white-space: nowrap;" block>
                        {{ item.name }}
                      </CButton>
                      <CButton v-else @click="getProductByCategory(item.objectId)" color="dark" variant="outline" block
                        style="white-space: nowrap;">
                        {{ item.name }}
                      </CButton>
                    </td>
                  </tr>
                </table>
              </div>

              <div style="line-height:50%;">
                <br />
              </div>
              <hr />

              <CRow>
                <CCol sm="12" lg="12">
                  <div v-if="loadingButton == true">
                    <CRow>
                      <CCol sm="12">
                        <h5 class="text-left font-weight-bold">
                          {{ $t('selectdProduct') }} {{ productSelectedTotal }} {{ $t('item') }} (
                          {{ $t('page') }} {{ meta_data.current_page }} )
                        </h5>
                      </CCol>
                    </CRow>

                    <table class="table table-striped" aria-describedby="" style="vertical-align: middle;">
                      <thead>
                        <th scope="col">
                          <input type="checkbox" @click="selectAll" v-model="allSelected"
                            class="form-control form-control-sm" />
                        </th>
                        <th scope="col">{{ $t('product') }}</th>
                        <th scope="col"></th>
                        <th scope="col">{{ $t('category') }}</th>
                        <th scope="col" class="text-left">{{ $t('price') }}</th>
                        <th scope="col" class="text-right">{{ $t('onhandQty') }}</th>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in data" :key="index">
                          <td class="text-left" style="width:55px">
                            <input type="checkbox" v-model="productIds" :value="item.objectId" @change="select(item)"
                              class="form-control form-control-sm" />
                          </td>
                          <td style="width:5%">
                            <CImg v-if="item.remoteImagePath != undefined" :src="item.remoteImagePath" width="50px"
                              style="border-radius: 5px" />
                            <div v-else style="border-radius: 5px" :style="{
                              width: '50px',
                              height: '40px',
                              backgroundColor: gerateIndexColor(
                                item.indexColor
                              ),
                            }"></div>
                          </td>
                          <td>
                            <h6 class="font-weight-bold text-grey">
                              {{ item.SKUName }} ( {{ unitName(item.unit) }} )
                            </h6>
                          </td>
                          <td>
                            <h6>
                              {{ item.category.name }}
                            </h6>
                          </td>
                          <td class="text-left">
                            <h6>฿ {{ item.SKUPrice }}</h6>
                          </td>
                          <td class="text-right">
                            <h6>{{ onhandQty(item.ProductSKUStock) }}</h6>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <CListGroupItem v-if="loadingButton == false">
                    <div class="text-center" width="100%">
                      <button class="btn" type="button" disabled style="color: darkcyan">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                        </span>
                        {{ $t('loading') }}
                      </button>
                    </div>
                  </CListGroupItem>
                </CCol>
              </CRow>
              <br />
              <template #footer-wrapper>
                <CRow class="justify-content-center">
                  <pagination :meta_data="meta_data" v-on:next="getProduct">
                  </pagination>
                </CRow>
                <hr />
                <CRow class="justify-content-center">
                  <CCol md="3">
                    <CButton color="primary" class="text-white" @click="productModal = false" block>
                      {{ $t('confirmItem') }} ( {{ productSelectedTotal }} {{ $t('item') }})
                    </CButton>
                  </CCol>
                  <CCol md="3">
                    <CButton color="dark" block @click="clearProduct">
                      {{ $t('cancel') }}
                    </CButton>
                  </CCol>
                </CRow>
                <br />
                <br />
              </template>
            </CModal>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <CRow>
      <CModal :show.sync="popupModal" :title="$t('confirmToSaveParicipateItemTitle')" color="success" centered
        :footer="footer">
        <h4 class="text-center">
          {{ $t('confirmToSaveParicipateItem') }} <br />
          {{ $t('total') }} {{ productSelected.length }} {{ $t('item') }}?
        </h4>
        <template #footer-wrapper>
          <CRow class="justify-content-center">
            <CCol col="4">
              <CButton block color="success" v-if="submitLoadingButton === true" v-on:click="formSubmit">
                {{ $t('save') }}
              </CButton>
              <CButton block color="success" v-else-if="submitLoadingButton === false" disabled>
                <CSpinner color="white" size="sm" /> {{ $t('save') }}
              </CButton>
            </CCol>
            <CCol col="4">
              <CButton block color="light" @click="popupModal = false">
                {{ $t('cancel') }}
              </CButton>
            </CCol>
          </CRow>
          <br />
          <br />
        </template>
      </CModal>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import util from '@/util/util'
import pos from '@/services/pos'
import crm from '@/services/crm'
import Pagination from '@/containers/Pagination'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      selected: [],
      allSelected: false,
      productSelected: [],
      productIds: [],
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
      },
      footer: '',
      loadingButton: true,
      deleteModal: false,
      keyword: '',
      categoryObjectId: '',
      countProduct: 0,
      productModal: false,
      category: [],
      popupModal: false,
      submitLoadingButton: true,
      isSuspend: '',
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    rewardcardObjectId() {
      return this.$route.params.rewardcardObjectId
    },
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    productSelectedTotal() {
      return this.productSelected.length
    },
    fields() {
      if (this.isSuspend === true) {
        return [
          { key: 'id', label: '#', _classes: 'text-center' },
          { key: 'PLUCode', label: this.$i18n.t('pluCode') },
          { key: 'name', label: this.$i18n.t('productName') },
          { key: 'unit', label: this.$i18n.t('unit'), _classes: 'text-center' },
          { key: 'categoryName', label: this.$i18n.t('category') },
        ]
      } else {
        return [
          { key: 'id', label: '#', _classes: 'text-center' },
          { key: 'PLUCode', label: this.$i18n.t('pluCode') },
          { key: 'name', label: this.$i18n.t('productName') },
          { key: 'unit', label: this.$i18n.t('unit'), _classes: 'text-center' },
          { key: 'categoryName', label: this.$i18n.t('category') },
          { key: 'delete', label: '', _classes: 'text-center' },
        ]
      }
    },
    items() {
      let data = this.productSelected
      let detail = []

      let unitName = '-'
      let PLUCode = '-'
      let SKUName = '-'

      for (let i = 0; i < data.length; i++) {
        if (data[i].ProductSKUStock === undefined) {
          detail = []
        } else {
          if (data[i].unit !== undefined) {
            unitName = data[i].unit.name
          }

          if (data[i].ProductPLU !== undefined) {
            PLUCode = data[i].ProductPLU[0].PLUCode
          }

          if (data[i].SKUName !== undefined) {
            SKUName = data[i].SKUName
          }

          detail.push({
            id: i + 1,
            objectId: data[i].objectId,
            PLUCode: PLUCode,
            name: SKUName,
            unit: unitName,
            categoryName: data[i].category.name,
          })
        }
      }

      return detail
    },
  },
  created() {
    this.getProduct()
    this.getCategory()
    this.getRewardCardByUID()
  },
  methods: {
    ...util,
    gerateIndexColor(color) {
      return util.generateColor(color)
    },
    onhandQty(productSKUStock) {
      if (productSKUStock !== undefined) {
        if (productSKUStock.onhandQty) {
          return productSKUStock.onhandQty
        }
      } else {
        return 'non-stock'
      }
    },
    unitName(unit) {
      if (unit !== undefined) {
        if (unit.name) {
          return unit.name
        }
      } else {
        return ''
      }
    },
    clearProduct() {
      this.productSelected = []
      this.productIds = []
    },
    removeItem(objectId) {
      let newProductIds = []
      let items = this.productSelected
      let newItems = items.filter((item) => item.objectId !== objectId)
      this.productSelected = newItems
      for (let i in this.productSelected) {
        newProductIds.push(this.productSelected[i].objectId)
      }
      this.productIds = newProductIds
    },
    select(item) {
      if (this.productIds.length === this.data.length) {
        this.allSelected = true
      } else {
        this.allSelected = false

        this.productSelected.push(item)

        let result = this.productSelected.filter((items) =>
          this.productIds.some((ids) => items.objectId === ids)
        )

        this.productSelected = result
      }
    },
    selectAll() {
      //ถ้าเลือก allSelected === false, ไม่เลือก === true
      if (this.allSelected === false) {
        for (let i in this.data) {
          this.productIds.push(this.data[i].objectId)
          this.productSelected.push(this.data[i])
        }

        let result = this.productSelected.reduce((unique, o) => {
          if (!unique.some((obj) => obj.objectId === o.objectId)) {
            unique.push(o)
          }
          return unique
        }, [])
        this.productSelected = result
      } else if (this.allSelected === true) {
        let newItems = this.productSelected.filter((oldItem) => {
          return !this.data.some(
            (newItem) => oldItem.objectId === newItem.objectId
          )
        })

        this.productSelected = newItems
        let newProductIds = this.productIds.filter((ids) =>
          this.productSelected.some((item) => item.objectId === ids)
        )

        this.productIds = newProductIds
      }
    },
    getCategory() {
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      pos
        .get('/api/v1.0/shop/' + uid + '/Category/data/' + shopObjectId)
        .then((res) => {
          this.category = res.data.data
        })
    },
    getProductByCategory(categoryObjectId) {
      this.categoryObjectId = categoryObjectId
      this.getProduct()
    },
    getProduct(page = 1) {
      this.searchLoadingButton = false
      this.loadingButton = false
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      let params = {}

      if (this.categoryObjectId === undefined || this.categoryObjectId === '') {
        params = {
          shopObjectId: shopObjectId,
          page: page,
          searchWord: this.keyword,
          limit: 25,
        }
      } else {
        params = {
          shopObjectId: shopObjectId,
          page: page,
          categoryObjectId: this.categoryObjectId,
          limit: 25,
        }
      }

      pos
        .get('/api/v1.0/' + uid + '/Shop/getproduct/data', { params })
        .then((res) => {
          this.allSelected = false
          this.data = res.data.data
          this.countProduct = this.data.length
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.countlist = res.data.length
          this.searchLoadingButton = true
          this.loadingButton = true
        })
    },
    getRewardCardByUID() {
      const uid = this.uid
      let documents = {}

      crm
        .get('/api/v1.0/' + uid + '/rewardcard/getbyuid')
        .then((response) => {
          documents = response.data.data.documents
          if (documents !== null) {
            this.isSuspend = documents.isSuspend
            this.productSelected = documents.items
            for (let i in this.productSelected) {
              this.productIds.push(this.productSelected[i].objectId)
            }
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    formSubmit() {
      this.submitLoadingButton = false

      const data = {
        objectId: this.rewardcardObjectId,
        uid: this.uid,
        items: this.productSelected,
      }

      if (data.items.length === 0) {
        alert('กรุณาเลือกสินค้าอย่างน้อย 1 รายการ')
        this.popupModal = false
      } else {
        crm({
          method: 'post',
          url: '/api/v1.0/' + data.uid + '/rewardcard/save',
          data: data,
        })
          .then((response) => {
            if (response.data.error.code === 0) {
              //บันทึกสำเร็จ
              this.submitLoadingButton = true
              this.popupModal = false
              this.$router.push('/crm/settings#rewardcard')
            } else {
              //มีบางอย่างผิดพลาด
              alert('มีบางอย่างผิดพลาด กรุณาตรวจสอบข้อมูลอีกครั้ง!')
            }
          })
          .catch((error) => {
            consolgoe.log(error)
          })
      }
    },
  },
}
</script>
